import * as echarts from 'echarts'

const borderColor = '#D2DCF3';
const fontColor = '#626D83';


const title = {
  text: '暂无数据',
  left: 'center',
  top: 'center',
  textStyle: {
    color: '#aaa',
    fontSize: 20
  },
}


const radar =  {
    shape: 'circle',
    splitNumber: 5,
    nameGap: 16,
    center:['50%','50%'],
    name: {
        textStyle: {
          //backgroundColor: '#73baf8',
          color: '#4379F9',
          fontSize: 12,
          borderRadius: 2,
          padding: 2
        }
    },
    splitArea: {
        show: false
    },
    splitLine: {
        lineStyle: {
            color: borderColor
        }
    },
    axisLine: {
        lineStyle: {
            color: borderColor
        }
    }
};

const radarSeries = {
  type: 'radar',
  itemStyle: {
    color: '#1890ff'
  },
  areaStyle: {
    opacity: 0.3
  },
  label: {
    show: true,
    position: 'inside',
    color: '#000'
  }
}

export function radarMultiOpts({labelArr=[], valueArr=[], nameArr=[]}) {
  const indicator = labelArr.map(o => ({name: o, max: 100}));
  let data = [];
  const color = ['#338dfa', '#fcc147'];
  if(valueArr.length) {
    data = valueArr.map((o,i) => ({
      symbolSize: 6,
      itemStyle: {
        color: color[i],
      },
      label: {
        position: 'top',
        color: color[i],
        fontSize: 12,
        distance: 1,
      },
      value: o,
      name: nameArr[i],
    }));
  }
  const option = {
      legend: {
          data: nameArr,
          top: '0px'
      },
      title: {
        ...title,
        show: !valueArr.length,
      },
      tooltip: {
        trigger: 'item',
        formatter: function (params) {
          return params.value.reduce((s, o, i) => (s = s + labelArr[i] + '：' + o + '<br/>'), '')
        }
      },
      grid: {
        containLabel: true
      },
      calculable : true,
      radar: {
        ...radar,
        indicator,
        radius: 100,
        center: ['50%', '50%'],
      },
      series: [
          {
              ...radarSeries,
              data,
          },
      ]
  };

  return option;
}

export function radarOpts({labelArr=[], valueArr=[]}) {
  const indicator = labelArr.map(o => ({name: o, max: 100}));
  let data = [];
  if(valueArr.length) {
    data = [{
      symbolSize: 6,
      itemStyle: {
        color: '#fcc147',
      },
      label: {
        position: 'top',
        color: '#b10212',
        fontSize: 12,
        distance: 1,
      },
      value: valueArr,
      name: '-',
    }]
  }
  const option = {
      title: {
        ...title,
        show: !valueArr.length,
      },
      tooltip: {
        trigger: 'item',
        formatter: function (params) {
          return params.value.reduce((s, o, i) => (s = s + labelArr[i] + '：' + o + '<br/>'), '')
        }
      },
      grid: {
        containLabel: true
      },
      calculable : true,
      radar: {
        ...radar,
        indicator,
        radius: 100,
        center: ['50%', '50%'],
      },
      series: [
          {
              ...radarSeries,
              data,
          },
      ]
  };

  return option;
}

export function tagOpts({labelArr=[], valueArr=[], tagTab=1}) {
  const tagName = {1: '开口率', 2: '开口次数'};
  const sign = {1: '%', 2: '次'};
  const option = {
    title: {
      ...title,
      show: !valueArr.length,
    },
    grid: {
        top: '0px',
        left: '0px',
        right: '70px',
        containLabel: true
    },
    calculable : true,
    yAxis: {
        nameTextStyle: {
          color: fontColor,
        },
        splitLine: {
            lineStyle: {
                color: borderColor
            }
        },
        axisLine: {
            lineStyle: {
                color: borderColor
            }
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          color: fontColor,
          show: true,
          interval:0,
          formatter: (val) => {
            if (val.length < 5) {
              return val;
            }
            const charArr = val.split('');
            let str = '' 
            for(let i = 0; i < charArr.length; i++) {
              const idx = Math.ceil(charArr.length / 2);
              str += charArr[i];
              if(i == idx-1) str += '\n';
            } 
            return str;
          }
        },
        type: 'category',
        name: '标签',
        data: labelArr
    },
    xAxis: {
        axisTick: {
          show: false
        },
        axisLabel: {
          color: fontColor,
          show: true,
        },
        nameTextStyle: {
          color: fontColor,
        },
        splitLine: {
          show: false,
        },
        axisLine: {
            lineStyle: {
                color: borderColor
            }
        },
        type: 'value',
        name: tagName[tagTab],
        boundaryGap: [0, 1]
    },
    series: [
        {
            type: 'bar',
            data: valueArr,
            barWidth: 10,
            itemStyle:{
                normal:{
                    color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{ 
                        offset: 0,
                        color: '#FAC15C'
                    }, {
                        offset: 1,
                        color: '#FAC15C'
                    }])

                }
            },
            label: {
              show: true,
              position: 'right',
              color: '#000',
              formatter: function(params) {
                return params.value + sign[tagTab];
              }
            }
        }
    ]
  };
  
  return option;
}


export function lengthTrendOpts({labelArr=[], valueArr=[], unit=''}) {
const option = {
    title: {
      ...title,
      show: !valueArr.length,
    },
    toolbox: {
        feature: {
            dataZoom: {
                yAxisIndex: false
            },
            saveAsImage: {
                pixelRatio: 2
            }
        }
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        }
    },
    calculable : true,
    grid: {
        bottom: 90
    },
    dataZoom: [{
        type: 'inside'
    }, {
		type: 'slider',
		show: true,
		height: 10,
		bottom: 10,
		borderColor: 'transparent',
		backgroundColor: '#E7F0FA',
		handleColor: '#015EEA',
		handleSize: 20,
		start: 0,
		end: 100
	}
],
    xAxis: {
        axisTick: {
          show: false
        },
        axisLabel: {
          color: fontColor,
          show: true,
        },
        nameTextStyle: {
          color: fontColor,
        },
        data: labelArr,
        name: '日期',
        silent: false,
        splitLine: {
          lineStyle: {
              color: borderColor
          }
        },
        axisLine: {
            lineStyle: {
                color: borderColor
            }
        },
        splitArea: {
            show: false
        }
    },
    yAxis: {
        axisTick: {
          show: false
        },
        axisLabel: {
          color: fontColor,
          show: true,
        },
        nameTextStyle: {
          color: fontColor,
        },
        splitLine: {
          lineStyle: {
              color: borderColor
          }
        },
        axisLine: {
            lineStyle: {
                color: borderColor
            }
        },
        name: unit,
        splitArea: {
            show: false
        }
    },
    series: [{
        type: 'bar',
        data: valueArr,
        large: true,
        //color: chartColor,
        barWidth: 38,
        itemStyle:{
            normal:{
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{ 
                    offset: 0,
                    color: '#FFB502'
                }, {
                    offset: 1,
                    color: '#FBE074'
                }])

            }
        },
        label: {
          show: true,
          position: 'top',
          color: '#000',
          formatter: function(params) {
            return params.value;
          }
        }
    }]
};

  return option;
}

