<template>
  <div class="box-car-list">
    <div v-if="!list.length" class="box-empty">
      暂无数据
    </div>
    <fragment v-else>
        <div class="item" v-for="(item, index) in list" :key="index"
          @click="jumpTo(item)">
            <div v-if="item.business == '交车'">
              <p class="name">
                <span class="name">{{item.car}}</span>
                <span v-if="isNaN(item.order_status)" class="status">
                  <span v-if="item.order_status=='数据处理中'" class="blue">{{item.order_status}}</span>
                  <span v-else-if="item.order_status=='评分中'" class="yellow">{{item.order_status}}</span>
                  <span v-else class="red">{{item.order_status}}</span>
                </span>
                <span v-else class="star">
                  <i class="i-star"></i>
                  <span class="star"><strong>{{item.order_status}}</strong> 分</span>
                </span>
              </p>
              <p>
                <span class="phone">
                  <i class="i-phone"></i>
                  <span>{{item.user_mobile}}</span>
                </span>
                <span class="long">接待时长{{item.hour}}小时{{item.minute}}分</span>
              </p>
              <p>
                <span class="time">接/交车时间 {{item.time}}</span>
              </p>
            </div>

            <div v-else>
            <p class="name">
              <span class="name">{{item.car}}</span>
              <span v-if="isNaN(item.order_status)" class="status">
                <span v-if="item.order_status=='数据处理中'" class="blue">{{item.order_status}}</span>
                <span v-else-if="item.order_status=='评分中'" class="yellow">{{item.order_status}}</span>
                <span v-else class="red">{{item.order_status}}</span>
              </span>
              <span v-else class="star">
                <i class="i-star"></i>
                <span class="star"><strong>{{item.order_status}}</strong> 分</span>
              </span>
            </p>
            <p>
              <span class="phone">
                <i class="i-phone"></i>
                <span>{{item.user_mobile}}</span>
              </span>
              <span class="time">{{item.business}} {{item.time}}</span>
            </p>
          </div>
        </div>
    </fragment>
  </div>
</template>
<script>
export default {
    props: {
      list: [],
      type: {
        type: String,
        default: '1'
      }
    },
    data() {
      return {
        dataList:[]
      }
    },
    methods: {
      jumpTo(item) {
        const {id} = item;
        this.$router.push({path: '/receptionShow', query: {id}});
      },
    },
    computed: {
    }
}
</script>


<style lang="less">
.box-empty {
  padding-top: 4rem;
  text-align: center;
  font-size: 1rem;
  color: #C8C9CC;
  img {
    width: 13rem;
  }
}
.box-car-list {
  padding: 0 0.75rem;
  overflow: scroll;
  .item {
    position: relative;
    background: #FFFFFF;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 0.25rem 0.5rem;

    p {
      margin: 0;
      padding: 0.25rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #000000;
      font-size: 0.7rem;

      .name {
        font-size: 1.1rem;
        font-weight: 500;
      }
      .time{
        color: #969799;
        font-weight: 400;
        font-size: 0.6rem;
      }
      .phone {
        font-weight: 400;
        display: flex;
        align-items: center;
      }
      .long {
        font-weight: 400;
        color: #377EEA;
        font-size: 0.6rem;
      }
      .star {
        strong {
          font-size: 1.1rem;
          font-weight: 500;
        }
      }
      .status {
        font-weight: 500;
        .blue {
          color: #377EEA;
        }
        .yellow {
          color: #FAC15C;
        }
        .red {
          color: #ED5A54;
        }
      }
    }
    .i-phone {
      display: inline-block;
      width: 1.3rem;
      height: 1.3rem;
      background: url('~@/assets/i_phone.png') no-repeat 0 0;
      background-size: 100% 100%;
      margin-right: 0.5rem;
    }
    .i-star {
      display: inline-block;
      width: 0.8rem;
      height: 0.8rem;
      background: url('~@/assets/i_star.png') no-repeat 0 0;
      background-size: 100% 100%;
      margin-right: 0.2rem;
    }
  }
}
</style>
