<template>
  <div class="page-reception-show bg-main">
    <div class="box-top">
      <div class="fixed">
        <div class="top-bar">
          <a @click="$router.back(-1)"><i class="el-icon-arrow-left"></i></a>
          <span>接待详情</span>
        </div>

        <div class="box-tab">
          <span v-for="(item,idx) in tabs" :key="idx"
            :class="item.id==curTab ? 'sel' : ''"
             @click="onChangeTab(item.id)"
                >{{item.title}}<i></i></span>
        </div>
      </div>
    </div>

    <div v-if="curTab=='t1'">
      <div v-if="dataList.length>1" class="box-type">
        <span v-for="(type, index) in dataList" :key="index"
          :class="index==curType ? 'sel' : ''"
          @click="onSelType(index)">
          {{type.business}}
        </span>
      </div>

        <fragment v-if="list.business==allType">
        <div class="title-score">
          <strong>环节评定</strong>
          <span class="">
            <i class="i-star"></i>
            <span>{{list.score}}分</span>
          </span>
        </div>
        <div class="sec">
            <div id="chart" class="chart"></div>
            <div class="box-progress">
              <div class="score" v-for="(item, idx) in list.checkResultList" :key="idx">
                <div class="circle" :style="`color: ${getColor(item, idx)}`">
                  <el-progress type="circle"
                    :width="45"
                    :stroke-width="4"
                    :percentage="item.scoreResult"
                    :color="color[idx]"
                    :format="p => `${p> 100 ? '-' : p + '%'}`"
                    ></el-progress>
                </div>
                <div class="line">
                  <div class="title">{{item.title}}</div>
                  <el-progress
                    :percentage="item.scoreResult"
                    :stroke-width="6"
                    :color="color[idx]"
                    :format="p => `${p> 100 ? '-' : p + '分'}`"
                    ></el-progress>
                </div>
              </div>
            </div>
        </div>
        </fragment>
        <fragment v-else>
        <div class="sec">
          <div v-if="list.score !== undefined" class="box-score">
            <div class="round" :style="`transform: rotate(${list.score * 1.8}deg);`"> <i></i> </div>
            <div class="ruling">
              <i v-for="(item, idx) in rules" :key="idx" :style="`transform: rotate(${idx * 18}deg);`"></i> 
            </div>
            <div class="bg-gray"></div>
            <div class="mask"></div>
            <div class="score">
              <div><strong>{{list.score}}</strong> 分</div>
              <div style="color: #969799; font-size: 0.6rem;">本次接待得分</div>
            </div>
          </div>
          <div class="box-progress">
              <div class="score" v-for="(item, idx) in list.checkResultList" :key="idx">
                <div class="circle" :style="`color: ${getColor(item, idx)}`">
                  <el-progress type="circle"
                    :width="45"
                    :stroke-width="4"
                    :percentage="item.scoreResult"
                    :color="color[idx]"
                    :format="p => `${p> 100 ? '-' : p + '%'}`"
                    ></el-progress>
                </div>
                <div class="line">
                  <div class="title">{{item.title}}</div>
                  <el-progress
                    :percentage="item.scoreResult"
                    :stroke-width="6"
                    :color="color[idx]"
                    :format="p => `${p> 100 ? '-' : p + '分'}`"
                    ></el-progress>
                </div>
              </div>
            </div>
        </div>
        <div class="box-hit">
          <div class="box-item" v-for="(item) in list.checkResultList" :key="item.id">
              <div class="title">{{item.title}}</div>
              <div class="sec">
              <div class="box-list" v-for="(list, idx) in item.hitResult" :key="idx">
                <div class="sub-title">{{list.title}}</div>
                <div class="box-tag">
                  <span class="tag" v-for="(tag) in list.children"
                    @click="setStartTime(tag.time)"
                    :style="{cursor: tag.state ? 'pointer' : ''}"
                    :key="tag.title">
                    {{tag.title}}
                    <img v-if="tag.state" src="@/assets/i_right.png"  class="i-check"/>
                    <img v-else src="@/assets/i_error.png"  class="i-check"/>
                  </span>
                </div>
              </div>
              </div>
          </div>
        </div>
      </fragment>
    </div>

      <TextRecord v-if="curTab=='t2'" />
      <CustomFile v-if="curTab=='t3'" />
      <CustomFocus v-if="curTab=='t4'" :business="business"/>

      <div class="box-agree" v-if="curTab=='t5'">
        <div class="sec">
          <div class="bg">
            <div class="intro1">扫描企业微信二维码</div>
            <div class="intro2">打开微信扫一扫</div>
          </div>
        </div>
      </div>
  </div>    
</template>

<script>
import * as echarts from 'echarts'
import { postForm } from '@/api'
import { radarMultiOpts } from '@/api/chart'
import TextRecord from './textRecord.vue';
import CustomFocus from './customFocus.vue';
import CustomFile from './customFile.vue';
const color = [
'#377EEA','#FAC15C','#11C16F','#ED5A54', '#3A15B1', '#9FEF00', 
'#FFD301','#FA7505','#CB0276', '#9241BE', '#55BBB9','#7A93CF',
]

export default {
  data() {
      return {
        allType: '接车+交车',
        business: '',
        color,
        id: '',
        curTab: 't1',
        tabs: [
          {title: '环节评定', id: 't1'},
          {title: '语音复盘', id: 't2'},
          {title: '客户档案', id: 't3'},
          {title: '客户关注点', id: 't4'},
          {title: '客户满意度', id: 't5'},
        ],
        detail: {},
        dataList: [],
        list: [],
        curType: 0,
        score: 0,
        rules: [...new Array(10)]
      }
  },
  computed: {
  },
  components: {
    TextRecord,
    CustomFocus,
    CustomFile,
  },
  methods: {
    getColor(item, idx) {
      return item.scoreResult > 0 && item.scoreResult < 101 ? color[idx] : '#000';
    },
    onSelType(idx) {
      this.myChart && this.myChart.clear();
      this.curType = idx;
      this.list = this.dataList[idx];
      this.$nextTick(() => {
        const domA = document.getElementById('chart');
        if (domA) {
          this.myChart = echarts.init(domA);
          this.myChart.setOption(radarMultiOpts(this.list.chartOpts));
        }
      });
    },
    onChangeTab(id) {
      this.curTab = id;
      if (id == 't1') {
        this.onSelType(0);
      }
    },
    getCheckResult() {
      postForm('/asservice/home/evaluation', {id: this.id}).then(res => {
          if (res.status === 1) {
            const {list, score} = res.data;
            if (list.length > 1) {
              this.business = '交车';
              const c1 = list[0].checkResultList;
              const c2 = list[1].checkResultList;
              const labels = [...c1, ...c2].map(o => o.title);
              const v1 = c1.map(o => (o.scoreResult < 101 ? o.scoreResult : '-'));
              const v2 = c2.map(o => (o.scoreResult < 101 ? o.scoreResult : '-'));
              const nameArr = list.map(o => (o.business));
              this.dataList = [
                {
                  business: this.allType,
                  score,
                  checkResultList: list.reduce((s, o) => ([...s, ...o.checkResultList]), []),
                  chartOpts: {
                    labelArr: labels,
                    valueArr: [[...v1, ...fillData(v2.length)], [...fillData(v1.length), ...v2]],
                    nameArr
                  }
                },
                ...list,
              ];

              console.log(this.dataList);
            } else {
              this.dataList = list || [];
              this.business = '接车';
            }
            this.onSelType(0);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
  mounted() {
    const {id} = this.$router.currentRoute.query || {};
    this.id = id;
    this.getCheckResult();
  }
}

function fillData(len) {
  return [... new Array(len)].map(() => 0);
}

</script>

<style lang="less">

.page-reception-show {
  .chart {
    padding-top: 1rem;
    margin: auto;
    width: 100%;
    height: 350px;
  }
  

  .box-type {
    margin: 0.8rem;
    display: flex;
    height: 2rem;
    line-height: 2rem;
    background: #F5F6F8;
    border-radius: 5px;
    opacity: 0.9;
    span {
      flex: 1;
      text-align: center;
      border-radius: 5px;
      color: #969799;
      font-weight: 400;
      font-size: 0.65rem;

      &.sel {
        background: #fff;
        color: #377EEA;
        font-weight: bold;
      }
    }
  }

  .title-score {
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    font-size: 0.7rem;
    font-weight: 400;

    strong {
      font-size: 0.8rem;
    }

      .i-star {
        display: inline-block;
        width: 0.7rem;
        height: 0.7rem;
        background: url('~@/assets/i_star.png') no-repeat 0 0;
        background-size: 100% 100%;
        margin-right: 0.2rem;
        vertical-align: top;
      }
  }
  .box-agree {
    .sec {
      min-height: 500px;
      padding: 2.5rem 0;
      text-align: center;
    }
    .bg {
      margin: auto;
      width: 280px;
      height: 359px;
      padding-top: 3rem;
      opacity: 1;
      background: url('~@/assets/bg_qrcode.png') top center no-repeat;
      background-size: 100% 100%;
    }
    .intro1 {
      color: #000000;
      font-weight: 400;
      font-size: 0.8rem;
    }
    .intro2 {
      margin-top: 0.5rem;
      color: #C8C9CC;
      font-weight: 400;
      font-size: 0.7rem;
    }
  }
  .box-score {
    margin: auto;
    height: 9rem;
    width: 15rem;
    position: relative;
    overflow: hidden;

    .bg-gray {
      background: #fff;
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 12.5rem;
      height: 12.5rem;
      transform: translate(-50%, 50%);
      border-radius: 50%;
      border: 0.5rem solid #F5F5F5;
    }

    .ruling {
      position: absolute;
      bottom: -7.5rem;
      left: 0;
      width: 15rem;
      height: 15rem;

      i {
        position: absolute;
        top: 50%;
        left: 0.4rem;
        right: 0.4rem;
        border-radius: 4px;
        height: 0.1rem;
        display: block;
        background: #fff;
        background: linear-gradient(90deg, #fff, transparent 1rem);
      }
    }

    .round {
      position: absolute;
      bottom: -7.5rem;
      left: 0;
      transform-origin: center;
      width: 15rem;
      height: 15rem;
      i {
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(#FAC15C 50%, #377EEA 50%);
        border-radius: 50%;
        border: 0.3rem solid #F5F5F5;
      }
    }
    .mask {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 45%);
      background: url('~@/assets/bg_score_v.png') bottom center no-repeat;
      background-size: auto 85%;
      background: #FFFFFF;
      width: 8.5rem;
      height: 8.5rem;
      border-radius: 50%;
      box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.15);
    }
    .score {
      position: absolute;
      bottom: 0.5rem;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 0.8rem;
      font-weight: 500;

      strong{
        font-size: 1.8rem;
      }
    }
  }

  .box-progress{
    margin-top: 1rem;
    padding: 0.5rem 0;
    border-top: 1px #EFEFEF solid;
    .score {
      display: flex;
      margin: 0.5rem 0;

      div.el-progress__text {
        color: inherit;
      }

      .circle {
        div.el-progress__text {
          font-size: 0.5rem !important;
        }
      }
      .line{
        flex: 1;
        padding-left: 0.5rem;
        .title {
          font-size: 0.6rem;
          font-weight: 400;
          color: #000;
        }

        .el-progress-bar {
          padding-right: 0;
        }
        .el-progress__text {
          position: absolute;
          top: -16px;
          right: 0;
        }
      }
    }
  }
.box-hit {
  .title {
    padding-left: 0.8rem;
    font-size: 0.8rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  .sec {
    padding: 0.8rem;
  }
  .box-item {
    margin-bottom: 1rem;
    .box-list {
      margin-top: 0.5rem;
      &:first-child {
        margin-top: 0;
      }
      .sub-title {
        font-size: 0.7rem;
        color: #000;
        font-weight: 500;
      }
    }
  }
  .box-tag {
    display: block;
    .tag{
      display: inline-block;
      margin: 0.5rem 0.5rem 0 0;
      padding: 0.1rem 0.5rem;
      background: #F5F5F5;
      border-radius: 4px;
      position: relative;
      font-size: 0.7rem;
      color: #222;

      .i-check {
        width: 0.7rem;
        height: 0.7rem;
        vertical-align: middle;
      }
    }
  }
}
  
  .box-top {
    height: 5rem;
    .fixed {
      padding-bottom: 1rem;
      z-index: 1000;
      background: url('~@/assets/bg_main.png') top center no-repeat;
      background-size: 100% auto;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
    }

    .top-bar {
      text-align: center;
      font-size: 0.8rem;
      font-weight: 400;
      padding: 1rem 0;

      a {
        position: absolute;
        left: 0.5rem;
        top: 1rem;
      }
    }
  }

  .box-tab {
    margin: 0 0.5rem;
    display: flex;
    font-weight: 400;
    font-size: 0.7rem;
    overflow-x: auto;
    overflow-y: hidden;
    height: 1.4rem;
    align-items: center;
    white-space: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }
    span{
      display: inline-block;
      flex: 1;
      text-align: center;
      padding: 0 0.5rem;

      i {
        display: block;
        width: 2.5rem;
        height: 3px;
        margin: 0.1rem auto;
        border-radius: 2px;
      }
    }
    span.sel{
      font-size: 0.8rem;
      font-weight: 500;

      i {
        background: #377EEA;
      }
    }
  }
}
</style>
