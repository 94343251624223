<template>
  <div class="page-focus">
    
    <h3 class="title">客户关注问题</h3>
    <div class="sec">
      <div class="list-type">
        <a :class="curQaType.label === item.label ? 'sel' : ''"
         v-for="(item,idx) in questionTypeList"
         :key="idx"
          @click="onSelType(item)">
          <span class="num"><strong>{{item.value}}</strong>{{item.unit}}</span>
          <span v-if="item.value !== ''">{{item.label}}</span>
        </a>
      </div>
    </div>
    <div class="sec" style="padding: 0 0 0.5rem 0;">
      <el-table class="table" stripe
        :data="questionHotList"
        style="width: 100%">
        <el-table-column
          width="50"
          align="center"
          type="index"
          label="排名"
          >
        </el-table-column>
        <el-table-column
          prop="label"
          align="center"
          label="热门问题"
          >
        </el-table-column>
        <el-table-column
          width="50"
          align="center"
          prop="value"
          label="次数">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { postForm } from '@/api'

export default {
  props: {
      business: {
        type: String,
        default: ''
      },
  },
  data() {
      return {
        curQaType: {},
        questionTypeList: [],
        questionHotList: [],
      }
  },
  methods: {
    onSelType(item) {
      this.curQaType = item || {};
      this.onSearch();
    },
    
    onSearch() {
      const typeId = this.curQaType.id;
      const type = {'交车': 'out', '接车': 'in'};
      postForm('/asservice/home/customerFocus', {
        id: this.id,
        label_id: typeId,
        business: type[this.business],
      }).then(res => {
          if (res.status === 1) {
            const {hotList:questionHotList, tagList:questionTypeList} = res.data;
            this.questionTypeList = questionTypeList || [];
            if (!typeId && this.questionTypeList.length > 0) {
              this.onSelType(this.questionTypeList[0]);
            }
            this.questionHotList = questionHotList;
          } else {
            this.$message.error(res.msg);
          }
        });
    }
  },
  components: {
  },
  created() {
    const {id} = this.$router.currentRoute.query || {};
    this.id = id;
    this.onSearch(true);
  },
}
</script>

<style lang="less">

.page-focus {
  position: relative;
  padding-bottom: 1rem;

  h3.title {
    padding-left: 0.8rem;
    font-size: 0.8rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .table {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    padding: 0;
    font-size: 0.6rem;
    &::before {
      height: 0;
    }
    thead {
      color: #000;
      th.el-table__cell {
        background: rgba(55,126,234,0.1);
      }
    }
    tbody {
      tr:nth-child(even){
        background: #F5F6F8;
      }
    }
    td.el-table__cell {
      padding: 0.4rem 0;
    }
    .cell {
      padding: 0;
    }
  }
  .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    border: 0;
  }
  
  .list-type {
    display: flex;
    flex-wrap: wrap;
    padding: 0.6rem 0;
    a {
      padding: 0.35rem 0;
      min-width: 30%;
      max-width: 33.3%;
      flex: 1;
      text-align: center;
      display: block;

      span {
        display: block;
        font-size: 0.6rem;
        color: #788798;

        &.num {
          color: #000;
        }

        strong {
          font-size: 0.9rem;
          margin-right: 0.1rem;
        }
      }
    }
    .sel {
      border-radius: 0.4rem;
      background: #EBF2FD;
      .num, strong, span {
        color: #377EEA;
      }
    }
  }
  
  .custom-info {
    .item {
      font-size: 0.8rem;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #EFEFEF;
      padding: 0.5rem 0;

      span {
        opacity: 0.64;
      }
      strong {
        font-weight: 400;
      }
      &:last-child {
        border: 0;
      }
    }
  }

}

</style>
