<template>
  <div class="page-count bg-main">
    <div class="box-top">
      <div class="fixed">
        <div class="box-tab">
          <p>
          <el-select v-model="ruleForm.date_type" placeholder="请选择" @change="loadData">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item"
              :value="item">
            </el-option>
            </el-select>
          </p>
          <p>
              <img v-if="true"
              class="icon" src="@/assets/i_date.png"/>
              <img v-else class="icon" src="@/assets/i_date_sel.png"/>
              <el-date-picker
                v-model="ruleForm.start_date"
                type="date"
                prefix-icon=" "
                value-format="yyyy-MM-dd"
                :picker-options="startOpts"
                format="yy.M.dd"
                @change="loadData"
                placeholder="开始日期">
              </el-date-picker>
              <span>-</span>
              <el-date-picker
                v-model="ruleForm.end_date"
                type="date"
                prefix-icon=" "
                value-format="yyyy-MM-dd"
                format="yy.M.dd"
                :picker-options="endOpts"
                @change="loadData"
                placeholder="结束日期">
              </el-date-picker>
          </p>
        </div>
      </div>
    </div>

    <h3 class="title">
      <strong>接待总览</strong>
      <span>
          <el-radio-group v-model="business.r1" @change="getTotal">
            <el-radio v-for="item in businessList" :key="item.id" :label="item.name">{{item.name}}</el-radio>
          </el-radio-group>
     </span>
    </h3>
    <div class="box-result sec">
      <div class="score" v-for="(item, idx) in totalAccount" :key="idx">
        <div class="num"><strong>{{item.value}}</strong></div>
        <div class="name">{{item.title}}({{item.unit}})</div>
      </div>
    </div>


    <h3 class="title">
      <strong>话术标签</strong>
      <span>
          <el-radio-group v-model="business.r3" @change="getLabel">
            <el-radio v-for="item in businessList" :key="item.id" :label="item.name">{{item.name}}</el-radio>
          </el-radio-group>
     </span>
    </h3>
    <div class="sec">
      <div class="box-tag">
        <span :class="tagTab == 1 ? 'sel' : ''" @click="changeTagTab(1)">
          开口率
        </span>
        <span :class="tagTab == 2 ? 'sel' : ''" @click="changeTagTab(2)">
          开口次数
        </span>
      </div>
      
      <div class="box-filter">
        <div class="item" v-for="(item,idx) in tagData" :key="idx" @click="changeTag(item)">
          <span :class="item.title === curTag.title ? 'i sel': 'i'"></span>
          {{item.title}}
        </div>
      </div>
      <div id="chartTag" style="width: 100%; height: 300px;"></div>
    </div>

    <h3 class="title">
      <strong>检核分析</strong>
      <span>
          <el-radio-group v-model="business.r2" @change="getChart">
            <el-radio v-for="item in businessList" :key="item.id" :label="item.name">{{item.name}}</el-radio>
          </el-radio-group>
     </span>
    </h3>
    <div class="sec" style="padding: 0.1rem">
      <div id="chart" style="width: 100%; height: 300px;padding-top: 0.5rem;"></div>
    </div>

    <h3 class="title">
      <strong>接待客户明细</strong>
      <span>
          <el-radio-group v-model="business.r4" @change="getList">
            <el-radio v-for="item in businessList" :key="item.id" :label="item.name">{{item.name}}</el-radio>
          </el-radio-group>
     </span>
    </h3>
    <div class="sec" style="padding: 0 0 0.5rem 0;">
      <ul class="table">
        <li class="header">
          <span class="c1">客户姓名</span>
          <span class="c2">业务类型</span>
          <span class="c3">接待时间</span>
          <span class="c4">接待时长</span>
          <span class="c5">接待得分</span>
        </li>
        <li v-for="(item, idx) in tableData" :key="idx">
          <span class="c1">{{item.seller_name}}</span>
          <span class="c2">{{item.business_name}}</span>
          <span class="c3">{{item.seller_time}}</span>
          <span class="c4">{{item.seller_length}}</span>
          <span class="c5">{{item.score}}</span>
        </li>
      </ul>
    </div>

    <NavBar v-if="bar!=='hide'" tab="统计分析"></NavBar>
  </div>
</template>

<script>
import Vue from 'vue'
import { postForm } from '@/api'
import NavBar from '@/components/navbar.vue';
import * as echarts from 'echarts'
import { tagOpts } from '@/api/chart'



const initType = '接车+交车';
export default {
  data() {
      return {
        bar: '',
        curTag: {},
        totalAccount: [],
        tableData: [],
        tagData: [],
        tagTab: 1,
        business: {
          r1: initType,
          r2: initType,
          r3: initType,
          r4: initType,
        },
        businessList: [
          {id: 3, name: '接车+交车'},
          {id: 1, name: '接车'},
          {id: 2, name: '交车'}
        ],
        options: ['近30天', '今日', '本周', '上周', '本月'],
        ruleForm: {
          date_type: '今日'
        },
        online: false,
        electricity: 0,
        startOpts: {
          disabledDate: (time) => {
              return new Date(time) > new Date(this.end_date || '')
          }
        },
        endOpts: {
          disabledDate: (time) => {
              return new Date(time) < new Date(this.start_date || '')
          }
        }
      }
  },
  components:{
    NavBar,
  },
  methods : {
    changeTagTab(l) {
      this.tagTab = l;
      this.changeTag(this.curTag, l);
    },
    changeTag(item) {
      this.curTag = item || {};
      const d = this.curTag.dataList || [];
      const labelArr = d.map(o => o.label);
      const timesArr = d.map(o => o.value);
      const rateArr = d.map(o => o.rate);
      const valueArr = this.tagTab == 2 ? timesArr : rateArr;
      this.tagChart.clear();
      this.tagChart.setOption(tagOpts({
        labelArr,
        valueArr,
        tagTab: this.tagTab
      }));
    },
    
    getTotal() {
      const {ruleForm} = this;
      postForm('/asservice/analysis/overview', {
        ...ruleForm,
        business: this.business.r1,
      }).then(res => {
        if (res.status === 1) {
          if (!res.data) {
            return;
          }
        this.totalAccount = res.data.totalAccountList || [];

        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getChart() {
      const {ruleForm} = this;
      postForm('/asservice/analysis/analyse', {
        ...ruleForm,
        business: this.business.r2,
      }).then(res => {
        if (res.status === 1) {
          if (!res.data) {
            return;
          }
        this.analyzeList = res.data.analyzeList;
        const tags = this.analyzeList;
        const labels = tags.map(o => o.title);
        const values = tags.map(o => parseFloat(o.avgValue));
        this.myChart.clear();
        this.myChart.setOption(radarOpts({labelArr: labels, valueArr: values}));

        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getLabel() {
      const {ruleForm} = this;
      postForm('/asservice/analysis/label', {
        ...ruleForm,
        //start_date: '2023-03-01',
        //end_date: '2023-04-30',
        business: this.business.r3,
      }).then(res => {
        if (res.status === 1) {
          if (!res.data) {
            return;
          }
          this.tagData = res.data.tagList || [];
          this.changeTag(this.tagData[0]);

        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getList() {
      const {ruleForm} = this;
      postForm('/asservice/analysis/lists', {
        ...ruleForm,
        business: this.business.r4,
      }).then(res => {
        if (res.status === 1) {
          if (!res.data) {
            return;
          }
        this.tableData = res.data || [];

        } else {
          this.$message.error(res.msg);
        }
      });
    },
    
    loadData() {
      this.getTotal();
      this.getChart();
      this.getLabel();
      this.getList();
    },
  },
  created() {
    const {bar, token} = this.$route.query;
    this.bar = bar;
    if(token) {
      Vue.ls.set('ACCESS_TOKEN', token)
    }
    this.loadData();
  },
  mounted () {
    const domA = document.getElementById('chart');
    this.myChart = echarts.init(domA);

    const dTag = document.getElementById('chartTag');
    this.tagChart = echarts.init(dTag);
    
  }
  
    
}

const gray = '#e0e0e0';
const radar =  {
    radius: '55%',
    shape: 'circle',
    splitNumber: 5,
    nameGap: 6,
    center:['50%','50%'],
    name: {
        textStyle: {
          color: '#4379F9',
          fontSize: 12,
          borderRadius: 2,
          padding: 2
        }
    },
    splitLine: {
        lineStyle: {
            color: gray
        }
    },
    splitArea: {
        show: false
    },
    axisLine: {
        lineStyle: {
            color: gray
        }
    }
};
const radarSeries = {
  type: 'radar',
  itemStyle: {
    color: '#fcc147'
  },
  areaStyle: {
    opacity: 0.3
  },
  label: {
    show: true,
    position: 'inside',
    color: '#b10212'
  }
}
const title = {
  text: '暂无数据',
  left: 'center',
  top: 'center',
  textStyle: {
    color: '#aaa',
    fontSize: 20
  },
}

function radarOpts({labelArr=[], valueArr=[]}) {
  const indicator = labelArr.map(o => ({name: o, max: 100}));
  let data = [];
  if(valueArr.length) {
    data = [{
      symbolSize: 6,
      label: {
        position: 'top',
        color: '#b10212',
        fontSize: 12,
        distance: 1,
      },
      value: valueArr,
      name: '-',
    }]
  }
  const option = {
      title: {
        ...title,
        show: !valueArr.length,
      },
      tooltip: {
        trigger: 'item',
        formatter: function (params) {
          return params.value.reduce((s, o, i) => (s = s + labelArr[i] + '：' + o + '<br/>'), '')
        }
      },
      calculable : true,
      radar: {
        ...radar,
        indicator
      },
      series: [
          {
              ...radarSeries,
              data,
          },
      ]
  };

  return option;
}

</script>

<style lang="less">

.page-count {
  .el-radio__inner {
    background: url('~@/assets/radio.png') center center no-repeat;
    background-size: cover;
    border: 0;
    &::after {
      display: none;
    }
  }
  .el-radio__input.is-checked .el-radio__inner {
    background: url('~@/assets/radio_sel.png') center center no-repeat;
    background-size: cover;

  }

  .box-filter {
    padding: 1rem;
    .item {
      display: inline-block;
      margin-right: 0.5rem;
      padding: 0.25rem 0;
      cursor: pointer;
      .i {
        width: 1rem;
        height: 0.25rem;
        background: #e8e8e8;
        display: inline-block;
        margin-right: 0.25rem;
        vertical-align: middle;

        &.sel {
          background: #6F95FC;
        }
      }
    }
  }
  
  .box-tag {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    span {
      font-size: 0.7rem;
      line-height: 2rem;
      width: 47%;
      text-align: center;
      border: 1px solid #F5F5F5;
      border-radius: 10px;
      background: #F5F5F5;

      &.sel {
        border: 1px solid #1890ff;
        color: #377EEA;
        background: #fff;
      }
    }
  }
  
  .table {
    color: #000;
    font-size: 0.6rem;
    li {
      display: flex;
      background: #fff;
      align-items: center;

      span {
        flex: 1;
        line-height: 1.3;
        padding: 0.5rem 0.2rem;
        max-width: 20%;
        word-break:break-all;

        &.c3 {
          min-width: 80px;
        }
      }

      &.header {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background: #EBF2FD;
        font-weight: 500;
        span {
          text-align: left;
        }
      }
      &:nth-child(even) {
        background: #F5F5F5;
      }
    }
  }
  h3.title {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    font-size: 0.8rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    display: flex;

    strong{
      font-weight: 400;
    }

    >span {
      flex: 1;
      text-align: right;
      .el-radio {
        margin-right: 10px;
      }
      .el-radio__input {
        width: 20px;
      }
      .el-radio__label {
        padding-left: 0;
        color: #000;
      }
    }
  }

  .box-result {
    padding: 0;
    display: flex;
    flex- wrap: wrap;
    .score {
      min-width: 50%;
      text-align: center;
      border-top: 0.05rem solid #EFEFEF;
      border-left: 0.05rem solid #EFEFEF;
      padding: 1rem 0;

      &:nth-child(1), &:nth-child(2) {
        border-top: 0;
      }
      &:nth-child(odd) {
        border-left: 0;
      }

      .num {
        font-size: 0.9rem;
      }
      .name {
        font-size: 0.6rem;
        color: #788798;
      }
    }
  }
.box-form {
  padding: 0.5rem 1rem;

  .el-form-item {
    margin-bottom: 1rem;
  }
  .el-form-item__label {
    font-weight: 400;
    line-height: 1.2;
  }

  .box-date {
    display: flex;

    .el-icon-date {
    }
  }
}

.box-top {
  height: 3rem;
  .fixed {
    z-index: 1000;
    background: url('~@/assets/bg_main.png') top center no-repeat;
    background-size: 100% auto;
    padding: 0.5rem 0.8rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}
.box-tab {
  display: flex;
  justify-content: space-between;
  >p {
    display: flex;
    align-items: center;
    max-width: 48%;
    flex: 1;
    background: #fff;
    border-radius: 0.6rem;
    height: 2.5rem;
    justify-content: center;
    font-size: 0.8rem;
    margin: 0;

    .el-input__inner {
      border: 0;
      padding: 0;
      text-align: center;
      font-size: 0.65rem;
      font-weight: 500;
    }
    .el-select {
      .el-input__inner, .el-select__caret {
        color: #377EEA;
      }
      .el-icon-arrow-up:before {
          content: "";
      }
    }
    .icon {
      height: 0.8rem;
      margin-left: 0.5rem;
    }
  }
}

.el-select .el-input {
  width: 5rem;
}
.input-with-select .el-input-group__prepend, .el-input-group__append {
  background-color: #fff;
}

}
</style>
