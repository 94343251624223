<template>
  <div class="page-member bg-main">
    <div class="box-title">个人中心</div>
    <div class="sec box-intro">
      <span class="img" :style="`background-image: url(${detail.img})`"></span>
      <div>{{detail.username}}</div>
    </div>
    <div class="sec">
      <ul class="item-justify">
    <!--
        <li @click="$router.push({name: 'password'})">
          <label>修改密码</label>
          <span><i class="el-icon-arrow-right"></i></span>
        </li>
    -->
        <li>
          <label>版本信息</label>
          <span>{{detail.version}}</span>
        </li>
      </ul>
    </div>
      <a class="logout" @click="logout">退出登录</a>
    <NavBar tab="我的"></NavBar>
</div>    
</template>

<script>
import Vue from 'vue'
import NavBar from '@/components/navbar.vue';
import { postForm } from '@/api'

export default {
  data(){
    return {
      curBar: 3,
      detail: {},
    }
  },
  components:{
    NavBar,
  },
  methods: {
    logout() {
      Vue.ls.remove('ACCESS_TOKEN')
      this.$router.push({name: 'login'});
    },
  },
  created() {
    postForm('/asservice/admin/my').then(res => {
        if (res.status === 1) {
          this.detail = res.data || {};
        } else {
          this.$message.error(res.msg);
        }
      });
  }
  
}
</script>

<style lang="less">
.page-member {
  position: relative;

  .logout {
    position: fixed;
    left: 2rem;
    right: 2rem;
    bottom: 5rem;
    background: #fff;
    border-radius: 0.5rem;
    line-height: 2.3rem;
    color: #ED5A54;
    font-size: 0.8rem;
    text-align: center;
  }
  
  .box-title {
    color: #000;
    text-align: center;
    font-size: 0.8rem;
    padding: 1rem;
  }

  .box-intro {
    margin-top: 3rem;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 500;
    padding-bottom: 1.5rem;

    .img {
      display: inline-block;
      margin-top: -2.1rem;
      margin-bottom: 1rem;
      width: 4.3rem;
      height: 4.3rem;
      border-radius: 50%;
      background-color: #333;
      background-repeat: no-repeat;
      background-size: cover;
      box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.1);
      border: 2px solid #FFFFFF;
    }
  }
}

</style>
