<template>
  <div class="page-file">
    
    <div class="sec">
      <div class="custom-info">
        <div class="item" v-for="(item, index) in customInfo" :key="index">
          <span class="label">{{item.label}}</span> 
          <strong>{{item.text}}</strong>
        </div>
      </div>
    </div>
    <h3 class="title">接待历史</h3>
    <div class="sec box-history">
      <el-timeline style="padding-top: 20px">
        <div class="main" v-for="(item, index) in history" :key="index">
        <div v-if="item.week" class="box-week">
          <span>{{item.week['cn']}}</span>
          <span>{{item.week['en']}}</span>
        </div>
        <el-timeline-item color="#377EEA" >
          <audio-player :src="item.audio_url" light/>
          <div class="box-card">
              <p class="title">
                <span class="name">{{item.seller_type}}</span>
                <span class="time">{{item.seller_time}}</span>
              </p>
              <p>
                <span>接待人：{{item.seller_name}}</span>
                <span class="">
                  <i class="i-star"></i>
                  <span>接待得分{{item.seller_score}}分</span>
                </span>
              </p>
              <p>
                <span class="box-icon">
                  <i class="i-edit" @click="onRemark(item)"></i>
                  <span class="box-list">
                    <span v-for="(t, i) in (item.remarks || '').split('\n')"
                          class="item" :key="i">・{{t}}</span>
                  </span>
                </span>
              </p>
          </div>
        </el-timeline-item> 
        </div>
      </el-timeline>
    </div>

    <el-drawer
      :with-header="false"
      :visible.sync="drawer"
      class="box-remark" >
      <div class="top-bar">
        <a @click="drawer=false" style="font-size: 1rem"><i class="el-icon-arrow-left"></i></a>
        <span>添加备注</span>
      </div>
      <div class="title">客户意向卡</div>
      <div class="box-form">
        <div class="base-info">
          <div class="item" v-for="(item, index) in customInfo" :key="index">
            <span class="label">{{item.label}}</span> 
            <strong>{{item.text}}</strong>
          </div>
        </div>
        <div class="box-record">
          <p style="font-size: 0.8rem; font-weight: 500;">接待记录</p>
          <p>
            <span style="color: #969799;">{{remarkInfo.seller_time}}</span>
            <span class="">
              <i class="i-star"></i>
              <span style="font-size: 0.7rem;">接待得分{{remarkInfo.seller_score}}分</span>
            </span>
          </p>
          <p>
            <el-input
              type="textarea"
              :rows="7"
              placeholder="添加备注"
              v-model="remark">
            </el-input>
          </p>

        </div>
        <div class="box-btn">
          <div>
            <el-button @click="remarkInfo={}">取消</el-button>
            <el-button style="background: #377EEA;" type="primary" @click="onSave">保存</el-button>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { postForm } from '@/api'
import { audioPlayer } from 'vue-md-player'
import 'vue-md-player/dist/vue-md-player.css'

export default {
  data() {
      return {
        customInfo: [],
        history: [],
        drawer: false,
        remarkInfo: {},
        remark: '',
      }
  },
  methods: {
    onSave() {
      const {id, seller_type} = this.remarkInfo;
      console.log(id, this.remarkInfo);
      const type = {'交车': 'out', '接车': 'in'};
      postForm('/asservice/home/remarks', {
        id,
        type: type[seller_type],
        remark: this.remark
      }).then(res => {
          if (res.status === 1) {
            this.drawer = false;
            this.onSearch();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    onRemark(item) {
      this.drawer = true;
      this.remarkInfo = item;
    },
    onSearch() {
      postForm('/asservice/home/customerInfo', {
        id: this.id,
      }).then(res => {
          if (res.status === 1) {
            const {info, history} = res.data;
            this.customInfo = [
              {label: '车牌号码', text: info.licence_number},
              {label: '客户姓名', text: info.name},
              {label: '客户手机号', text: info.user_mobile},
              {label: '行驶公里数', text: info.driven_km},
            ];
            this.history = history;
          } else {
            this.$message.error(res.msg);
          }
        });
    }
  },
  components: {
    audioPlayer,
  },
  created() {
    const {id} = this.$router.currentRoute.query || {};
    this.id = id;
    this.onSearch(true);
  },
}
</script>

<style lang="less">

.page-file {
  position: relative;
  padding-bottom: 1rem;

  .el-timeline-item {
    &:last-child .el-timeline-item__tail {
      display: block;
    }
    &:after {
      content: ' ';
      width: 0.35rem;
      height: 0.35rem;
      border-radius: 50%;
      background: #377EEA;
      position: absolute;
      top: 2.6rem;
      left: 0.07rem;
    }
    .el-timeline-item__wrapper {
      top: -20px;
    }
    .el-timeline-item__node {
      border: 3px solid #fff;
      box-shadow: 0 0 2px 2px #377EEA;
    }
    .el-timeline-item__tail {
      border-left: 1px dashed #B1B1B1;
    }
  }
  .box-remark {
    .el-drawer {
      background: #F5F6F8;
      width: 100% !important;
      .el-drawer__body {
        display: flex;
        flex-direction: column;
      }
    }
    .title {
      margin-top: 0.5rem;
      font-size: 0.8rem;
      font-weight: 500;
      padding: 0 0.8rem;
    }
    .top-bar {
      text-align: center;
      font-size: 0.9rem;
      font-weight: 400;
      padding: 1rem 0;

      a {
        position: absolute;
        left: 0.5rem;
        top: 1rem;
      }
    }

    .box-form {
      flex: 1;
      margin-top: 0.9rem;
      background: #fff;
      border-radius: 0.5rem;
      padding: 0 0.8rem;


      .base-info {
        .item {
          display: flex;
          border-bottom: 1px solid #EFEFEF;
          padding: 0.5rem 0;
          font-weight: 400;

          span {
            font-size: 0.8rem;
            width: 5.5rem;
          }
          strong {
            font-weight: 400;
            font-size: 0.7rem;
          }
        }
      }

      .box-record {
        padding-bottom: 1rem;
        margin-top: 1rem;

        textarea {
          border: 0;
          background: #F5F6F8;
        }
        p {
          margin: 0 0 0.5rem 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #000000;
          font-size: 0.6rem;
          font-weight: 400;

          .i-star {
            display: inline-block;
            width: 0.7rem;
            height: 0.7rem;
            background: url('~@/assets/i_star.png') no-repeat 0 0;
            background-size: 100% 100%;
            margin-right: 0.2rem;
            vertical-align: top;
          }
        }
      }

      .box-btn {
        border-top: 1px solid #EFEFEF;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin-bottom: 0;

        >div {
          position: relative;
          display: flex;
          height: 2.5rem;

          button {
            margin: 0;
            flex: 1;
            border: 0;
            border-radius: 0;
            line-height: 2.5rem;
            padding: 0;
            font-size: 0.8rem;
            font-weight: 400;
          }

        }
      }
    }
  }

  .vuemdplayer {
    min-height: 50px;
    .control-bar {
      padding: 0 0 0 40px;
      height: 50px;
    }

    .layout {
      position: relative;
      display: block;
    }
    .player-btn {
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(-100%, -50%) scale(80%);
      background: #377EEA;
      svg {
        fill: #fff;
      }
    }
    .times {
      padding: 0;
      opacity: 0.36;
      display: flex;
      justify-content: space-between;

      span:nth-child(2) {
        display: none;
      }
    }
  }
  h3.title {
    padding-left: 0.8rem;
    font-size: 0.8rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .custom-info {
    .item {
      font-size: 0.8rem;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #EFEFEF;
      padding: 0.5rem 0;
      font-weight: 400;

      span {
        opacity: 0.64;
      }
      strong {
        font-weight: 400;
      }
      &:last-child {
        border: 0;
      }
    }
  }
  .box-history {
    padding: 0 0.5rem 0 0;
    .main {
      position: relative;
      padding-left: 2rem;
    }
    .box-week {
      position: absolute;
      top: 1.8rem;
      left: 0;
      width: 2rem;
      span {
        display: block;
        text-align: center;
        font-size: 0.6rem;
        line-height: 1.5;
      }
    }
    .box-card {
      border-radius: 10px;
      background: #F5F5F8;
      padding-bottom: 0.25rem;

      .title {
        color: #fff;
        background: #377EEA;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 0.5rem;
      }
      p {
        margin: 0 0 0.5rem 0;
        padding: 0 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #000000;
        font-size: 0.6rem;
        font-weight: 400;

        .name {
          font-size: 0.7rem;
          font-weight: 500;
        }
        .time{
        }

        .box-icon {
          display: flex;

          .box-list {
            flex: 1;
            color: #888888;
            word-break: break-all;
            .item {
              margin-bottom: 0.4rem;
              display: block;
              line-height: 1.1;
            }
          }
        }
        .i-mic {
          display: inline-block;
          width: 0.8rem;
          height: 0.8rem;
          background: url('~@/assets/i_mic.png') no-repeat 0 0;
          background-size: 100% 100%;
          margin-right: 0.2rem;
        }
        .i-edit {
          display: inline-block;
          width: 0.8rem;
          height: 0.8rem;
          background: url('~@/assets/i_edit.png') no-repeat 0 0;
          background-size: 100% 100%;
          margin-right: 0.2rem;
        }
        .i-star {
          display: inline-block;
          width: 0.7rem;
          height: 0.7rem;
          background: url('~@/assets/i_star.png') no-repeat 0 0;
          background-size: 100% 100%;
          margin-right: 0.2rem;
          vertical-align: top;
        }
      }
    }
  }

}

</style>
