<template>
  <div class="box-camera">
    <div class="top-bar">
        <i class="el-icon-close" @click="onClose"></i>
    </div>
    <div style="width: 100%;">
      <div v-if="denied">授权失败，无法使用该功能。</div>
      <video v-else class="video" :class="facingMode === 'user' ? 'front' : ''" ref="video" />
      <canvas style="display: none" ref="canva" />
    </div>

    <div class="box-btn">
    <span class="photo-button" @click="TakePhoto">
      <i class="el-icon-camera-solid"></i>
    </span>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      mediaStream: null,
      videoDevices: [],
      facingMode: "environment",
      denied: false,
    };
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    async StartRecording(facingMode) {
      this.facingMode = facingMode;
      let video = this.$refs.video;
      try {
        this.denied = false;
        this.mediaStream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: facingMode },
        })
      } catch {
        this.denied = true;
      }
      video.srcObject = this.mediaStream;
      return await video.play();
    },
    async TakePhoto() {
      let video = this.$refs.video;
      let canva = this.$refs.canva;
      let width = video.videoWidth;
      let height = video.videoHeight;
      canva.width = width;
      canva.height = height;
      let ctx = canva.getContext("2d");
      ctx.save();
      if (this.facingMode === "user") {
        ctx.scale(-1, 1);
        ctx.drawImage(video, width * -1, 0, width, height);
      } else {
        ctx.drawImage(video, 0, 0);
      }
      ctx.restore();
      const photo = dataURLtoBlob(canva.toDataURL("image/png"));
      this.$emit('stop', new File([photo], 'chepai'+new Date().getTime()));
    },
    async handler() {
      const devices = await navigator.mediaDevices.enumerateDevices();
      this.videoDevices = devices.filter((d) => d.kind === "videoinput");
      await this.StartRecording(
        this.videoDevices.length === 1 ? "user" : "environment"
      )
    },
  },
  mounted() {
    if (navigator && navigator.mediaDevices) {
      this.handler();
    }
  },
  destroyed() {
  },
};

function dataURLtoBlob (dataurl) { 
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

</script>

<style lang="less" scoped>
.box-camera {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  display: flex;
  justify-items: center;
  align-items: center;
  overflow: hidden;
  z-index: 10000;
  color: #fff;
  text-align: center;
  font-size: 0.8rem;

  .top-bar {
    position: fixed;
    top: 1rem;
    right: 0;
    left: 0;
    color: #fff;
    z-index: 10001;
    font-size: 1.5rem;
    color: #fff;
    text-align: right;
    padding-right: 1rem;
  }

  .video {
  }
  .box-btn {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 2rem;
    text-align: center;
    .photo-button {
      display: inline-block;
      overflow: hidden;
      width: 3rem;
      height: 3rem;
      line-height: 3.2rem;
      border-radius: 100%;
      background-color: #fff;
      color: #000;
      font-size: 2rem;
    }
  }
}
</style>
