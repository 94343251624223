<template>
  <div class="box-bar">
    <div class="fix-bar">
    <p v-for="(item,idx) in list" :key="idx"
      :class="item.title==tab ? 'sel' : ''"
       @click="jump(item)"
      >{{item.title}}</p>
    </div>
  </div>
</template>
<script>
export default {
    props: {
      tab: {
        type: String,
        default: '我的'
      },
    },
    data() {
      return {
        list: [
          {title: '销售服务', link: 'index'},
          {title: '统计分析', link: 'count'},
          {title: '我的', link: 'member'},
        ],
      }
    },
    methods: {
      jump(item) {
        if (item.title === this.tab) {
          return;
        }
        this.$router.push({name: item.link});
      }
    },
    computed: {
    }
}
</script>
<style lang="less">
.box-bar {
  height: 3.5rem;
}
.fix-bar {
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  background: #fff;
  padding: 0.5rem 0;
  p {
    font-size: 0.6rem;
    color: #C8C9CC;
    flex: 1;
    text-align: center;
    padding-top: 1.4rem;
    background-size: auto 1.2rem;
    background-position: center top;
    background-repeat: no-repeat;
    margin: 0;

    &:nth-child(1) {
      background-image: url('~@/assets/bar_index.png');
    }
    &:nth-child(2) {
      background-image: url('~@/assets/bar_count.png');
    }
    &:nth-child(3) {
      background-image: url('~@/assets/bar_my.png');
    }

    &.sel {
      color: #377EEA;
    }
    &:nth-child(1).sel {
      background-image: url('~@/assets/bar_index_sel.png');
    }
    &:nth-child(2).sel {
      background-image: url('~@/assets/bar_count_sel.png');
    }
    &:nth-child(3).sel {
      background-image: url('~@/assets/bar_my_sel.png');
    }
  }
}
</style>
