<template>
  <div class="page-list bg-main">
    <div class="box-top" :style="{height: fixHeight + 'px'}">
      <div ref="fixed" class="fixed">
        <fragment v-if="pageName=='list'">
          <div class="top-bar">
            <a @click="$router.back(-1)" style="font-size: 1rem"><i class="el-icon-arrow-left"></i></a>
          </div>
          <div class="box-search" @click="showDrawer=true">
            <img src="@/assets/i_search.png" />
            <el-input v-model="ruleForm.name" readonly placeholder="请输入内容">
            </el-input>
            <span class="btn">筛选</span>
          </div>
        </fragment>
      
        <fragment v-else>
          <div class="box-tab">
            <p v-for="(item,idx) in tabs" :key="idx"
              :class="item.id==curTab ? 'sel' : ''"
               @click="onChangeTab(item.id)"
               > <i></i>{{item.title}} </p>
          </div>
          <div style="display: flex;">
            <div class="box-search" @click="showDrawer=true">
              <img src="@/assets/i_search.png" />
              <el-input v-model="ruleForm.name" readonly placeholder="请输入内容">
              </el-input>
              <span class="btn">筛选</span>
            </div>
              <div class="box-car" @click="onScan">
                <span class="btn">扫车牌</span>
              </div>
            <!--
            <el-upload :auto-upload="false" :show-file-list="false" :on-change="onTest" >
                <span>扫车牌</span>
            </el-upload>
              -->
          </div>
        </fragment>
      </div>
    </div>

    <QueryList :list="dataList" :type="String(curTab)"/>

    <div v-if="showMore" style="text-align: center; padding: 1rem 0; color: #999" @click="onSearch()">点击加载更多 <i class="el-icon-arrow-down"></i></div>

    <el-drawer
      class="pop-search"
      title="筛选"
      direction="btt"
      size="99%"
      :visible.sync="showDrawer"
      >
      <el-form :model="ruleForm"
        status-icon ref="ruleForm"
        label-position="top" 
        class="box-form"
        >
        <el-form-item class="title-inline">
          <label>按日期</label>
          <div class="box-date">
            <el-date-picker
              @change="startChange"
              v-model="ruleForm.start_date"
              type="date"
              prefix-icon=" "
              value-format="yyyy-MM-dd"
              clear-icon
              placeholder="开始日期">
            </el-date-picker>
            <el-date-picker
              @change="endChange"
              v-model="ruleForm.end_date"
              type="date"
              prefix-icon=" "
              value-format="yyyy-MM-dd"
              clear-icon
              placeholder="结束日期">
            </el-date-picker>
          </div>
          <img class="ic" src="@/assets/i_date_d.png" />
        </el-form-item>
        <el-form-item class="title-inline">
          <label>车牌</label>
          <el-input type="text" v-model="ruleForm.username" placeholder="请输入车牌">
          </el-input>
          <img class="ic" src="@/assets/i_search.png" />
        </el-form-item>
        <el-form-item class="title-inline">
          <label>手机号</label>
          <el-input type="text" v-model="ruleForm.phone" placeholder="请输入手机号"></el-input>
          <img class="ic" src="@/assets/i_search.png" />
        </el-form-item>
        <el-form-item label="接待环节">
          <el-radio-group v-model="ruleForm.type" class="group-btn">
            <el-radio
              v-for="(item,idx) in types" :key="idx"
              :label="item.id" border>{{item.name}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="接待状态">
          <el-radio-group v-model="ruleForm.statue" class="group-btn">
            <el-radio
              v-for="(item,idx) in states" :key="idx"
              :label="item.id" border>{{item.name}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="评分状态">
          <el-radio-group v-model="ruleForm.score_status" class="group-btn">
            <el-radio
              v-for="(item,idx) in scoreStatus" :key="idx"
              :label="item.id" border>{{item.name}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="pop-btn">
          <el-button @click="ruleForm={}">重置</el-button>
          <el-button type="primary" @click="onFilter">筛选</el-button>
        </el-form-item>
      </el-form>
      </el-drawer>

    <Camera v-if="showScan" @stop="onUpload" @close="showScan=false"/>
    <fragment v-if="bar!=='hide'">
      <NavBar v-if="pageName!=='list'" tab="销售服务"></NavBar>
    </fragment>
  </div>
</template>

<script>
import Vue from 'vue'
import { postForm, uploadFile } from '@/api'
import NavBar from '@/components/navbar.vue';
import QueryList from '@/components/querylist.vue';
import Camera from '@/components/camera.vue';


export default {
  data() {
      return {
        showScan: false,
        bar: '',
        ruleForm: {
        },
        curTab: 1,
        cardId: '',
        tabs: [
          {title: '接车', id: 1},
          {title: '交车', id: 2},
        ],
        states: [{
          id: 1,
          name: '待接车'
        },
        {
          id: 2,
          name: '接车中'
        },
        {
          id: 3,
          name: '待交车'
        },
        {
          id: 4,
          name: '交车中'
        }],
        scoreStatus: [{
          id: 1,
          name: '无录音'
        },
        {
          id: 2,
          name: '录音超长'
        },
        {
          id: 3,
          name: '评分中'
        },
        {
          id: 4,
          name: '数据处理中'
        }],
        types: [{
          id: 1,
          name: '接车'
        },
        {
          id: 2,
          name: '交车'
        }],
        sn: '',
        showDrawer: false,
        showPop: false,
        isEmpty: false,
        pageNum: 1,
        pageSize: 10,
        dataList: [],
        showMore: false,
        online: false,
        electricity: 0,
        fixHeight: '',
        startOpts: {
          disabledDate: (time) => {
              return new Date(time) > new Date(this.end_date || '')
          }
        },
        endOpts: {
          disabledDate: (time) => {
              return new Date(time) < new Date(this.start_date || '')
          }
        }
      }
  },
  watch: {
    $route: {
      handler (to, from) { 
        if (to.path != from.path) {
          this.$router.go(0);
        }
      },
    }
  },
  components:{
    NavBar,
    QueryList,
    Camera,
  },
  methods : {
    onTest(e) {
      this.onUpload(e.raw);
    },
    onUpload(file) {
      uploadFile('/asservice/analysis/recognizeCarNumber', file, 'img').then(res => {
        this.showScan = false;
        const {status, data, msg} = res.data || {};
        if (status === 1) {
          this.dataList = data;
        } else {
          this.$message.error(msg);
        }
      });
      /*
      */
    },
    onScan() {
      this.showScan = true;
    },
    onFilter() {
      if (this.pageName == 'list') {
        this.onSearch(1);
      } else {
        this.$router.push({
          name: 'list',
          query: this.ruleForm
        });
      }
    },
    closeDrawer() {
      this.ruleForm = {};
      this.showDrawer = false;
    },
    onChangeType(item) {
      this.$router.push({path: '/reception', query: {id: this.cardId, type: item}});
    },
    onChangeTab(id) {
      this.curTab = id;
      //this.$router.replace({path: '/index', query: {id}});
      this.onSearch(1);
    },
    endChange(v) {
      this.end_date = v;
    },
    startChange(v) {
      this.start_date = v;
    },
    onSearch(num) {
      const {id} = this.$router.currentRoute.query || {};
      id && (this.curTab = id);

      const pageNum = num || this.pageNum;
      const {pageSize, curTab, ruleForm} = this;
      postForm('/asservice/home/index', {
        ...ruleForm,
        type: ruleForm.type || curTab,
        pageSize,
        pageNum
      }).then(res => {
        if (res.status === 1) {
          if (!res.data) {
            return;
          }

          if (pageNum === 1) {
            this.dataList = res.data;
          } else {
            this.dataList = [...this.dataList, ...res.data];
          }

          this.isEmpty = !this.dataList.length;
          this.pageNum = Number(res.pageNum) + 1;
          const total = Number(res.total);
          this.showMore = total > this.dataList.length;

          this.showDrawer = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    
  },
  created() {
    this.pageName = this.$router.currentRoute.name;
    const {bar, token, ...rest} = StringToNum(this.$route.query);
    this.bar = bar;
    if(token) {
      Vue.ls.set('ACCESS_TOKEN', token)
    }
    this.ruleForm = {
      ...rest
    }
    this.onSearch(1);
  },
  mounted() {
    this.fixHeight = this.$refs.fixed.offsetHeight;
  }
    
}
function StringToNum(obj){
  for(let i in obj){
    if(i!='start_date'&&i!="end_date"){
      obj[i] = (isNaN(Number(obj[i])) || !obj[i]) ? obj[i] : Number(obj[i]);
    }

  }
    return obj
}

</script>

<style lang="less">

.page-list {
.box-form {
  padding: 0.5rem 1rem;

  .el-form-item {
    margin-bottom: 1rem;
  }
  .el-form-item__label {
    font-weight: 400;
    line-height: 1.2;
  }

  .title-inline {
    .el-form-item__content {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #EFEFEF;

      .el-input__inner {
        border: 0;
      }
      label {
        white-space: nowrap;
        padding-right: 0.5rem;
        font-weight: 400;
        line-height: 1.2;
        font-size: 0.8rem;
      }
    }
  }
  .box-date {
    display: flex;

    .el-icon-date {
    }
  }
  .group-btn {
    justify-content: pop-type;
    >label {
      margin: 0.25rem 0 !important;
      width: 48%;
    }
  }
}

.pop-search {
  .el-drawer {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  .el-drawer__header {
    font-size: 1rem;
    color: #000;
  }

  .ic {
    position: absolute;
    right: 0;
    bottom: 0.4rem;
    width: 1rem;
    height: 1rem;
  }
  .pop-btn {
    border-top: 1px solid #EFEFEF;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 0;

    .el-form-item__content {
      position: relative;
      display: flex;
      line-height: 2rem;
      height: 2rem;

      button {
        flex: 1;
        border: 0;
        border-radius: 0;
        line-height: 2rem;
        padding: 0;
      }

    }
  }
}

.box-fixed {
  position: fixed;
  bottom: 4rem;
  left: 0;
  right: 0;
  text-align: center;
}
.box-fixed a{
  display: inline-block;
  padding: 0.4rem 2.5rem;
  color: #fff;
  border-radius: 20px;
  font-size: 0.8rem;
  background: linear-gradient(132deg, #377EEA 0%, rgba(55,126,234,0.76) 100%);
  box-shadow: 0px 4px 10px 0px rgba(55,126,234,0.2);
}

.box-top {
  .fixed {
    z-index: 1000;
    background: url('~@/assets/bg_main.png') top center no-repeat;
    background-size: 100% auto;
    padding: 0.5rem 0.8rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}
.box-tab {
  display: flex;
  justify-content: space-between;
  p {
    display: flex;
    align-items: center;
    max-width: 48%;
    flex: 1;
    background: #fff;
    border-radius: 0.6rem;
    height: 3rem;
    justify-content: center;
    font-size: 0.8rem;
    margin: 0;
    i {
      display: inline-block;
      margin-right: 0.5rem;
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: cover;
    }
    &:nth-child(1) i{
      background-image: url('~@/assets/i_tab2.png');
      height: 1rem;
      width: 1rem;
    }
    &:nth-child(2) i{
      height: 1rem;
      width: 1rem;
      background-image: url('~@/assets/i_tab1.png');
    }

    &.sel {
      color: #377EEA;
    }
    &:nth-child(1).sel i{
      background-image: url('~@/assets/i_tab2_sel.png');
    }
    &:nth-child(2).sel i{
      background-image: url('~@/assets/i_tab1_sel.png');
    }
  }
}
.box-car {
  height: 2.2rem;
  margin-top: 0.5rem;
  margin-left: 0.7rem;
  display: flex;
  align-items: center;
  border-radius: 0.6rem;
  background: #fff url('~@/assets/i_scan.png') 50% 0.2rem no-repeat;
  background-size: auto 0.7rem;

  .btn {
    color: #000;
    display: inline-block;
    font-size: 0.6rem;
    padding: 0 1rem;
    text-align: center;
    margin-top: 0.9rem;
  }
}
.box-search {
  height: 2.2rem;
  flex: 1;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 0.6rem;
  img {
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  input {
    flex: 1;
    border: 0;
    line-height: 2rem;
    height: 2rem;
  }
  .btn {
    display: inline-block;
    font-size: 0.65rem;
    border-left: 1px solid #EFEFEF;
    text-align: center;
    margin: 0.4rem 0;
    line-height: 1.2rem;
    white-space: nowrap;
    padding: 0 1rem;
  }
}

.el-select .el-input {
  width: 5rem;
}
.input-with-select .el-input-group__prepend, .el-input-group__append {
  background-color: #fff;
}

}
</style>
